import React from "react";
import { graphql, Link } from "gatsby";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { IframeContainer } from "../components/site/";

import { BlogPost } from "../components/page-template/blog-post";

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(url: { eq: $slug }) {
      publishedDate(formatString: "MMMM DD, YYYY")
      image {
        file {
          url
        }
        gatsbyImageData(width: 600, layout: FIXED, quality: 100)
      }
      url
      title
      topic
      description
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
      author
      tags
    }
  }
`;


const Post = (props) => {
  const website_url = process.env.GATSBY_SITE_URL;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (
          node.data.target.link.startsWith(website_url) ||
          node.data.target.link.startsWith("/") ||
          node.data.target.link.startsWith("#")
        ) {
          return (
            <Link to={node.data.target.link}>
              <GatsbyImage
                className="-addShadow"
                image={node.data.target.image.gatsbyImageData}
                alt={node.data.target.imageAltText}
                title={node.data.target.imageAltText}
              />
            </Link>
          );
        } else {
          return (
            <a
              href={node.data.target.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                className="-addShadow"
                image={node.data.target.image.gatsbyImageData}
                alt={node.data.target.imageAltText}
                title={node.data.target.imageAltText}
              />
            </a>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.title}
            title={node.data.target.title}
          />
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri.startsWith("#ID=")) {
          return (
            <span
              className="post-anchor"
              css={{ opacity: "0" }}
              id={node.data.uri.replace("#ID=", "")}
            >
              &nbsp;
            </span>
          );
        } else if (node.data.uri.includes("player.vimeo.com/video")) {
          return (
            <IframeContainer
              title="FlexiTime Vimeo Video"
              src={node.data.uri}
            />
          );
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <IframeContainer
              title="FlexiTime YouTube Video"
              src={node.data.uri}
            />
          );
        } else if (
          node.data.uri.startsWith(website_url) ||
          node.data.uri.startsWith("/") ||
          node.data.uri.startsWith("#")
        ) {
          return (
            <Link to={node.data.uri}>
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </Link>
          );
        } else {
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </a>
          );
        }
      },
    },
  };

  return (
    <BlogPost blog={props.data.contentfulBlog}
      contentOptions={options}
      location={props.location}
      isTemplate={true}
    />
  );
};

export default Post;
